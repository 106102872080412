import React from 'react';
import { Link } from 'gatsby';
import Picture from './Picture';

const CardArticle = ({ type = 'small', article }) => {

	const imageSrc = article.image.fluid ? article.image.fluid.src : article.image.url;
	const imageSrcSet = article.image.fluid ? article.image.fluid.srcSet : '';
	const title = article.title.text || article.title[0].text;
	let shortDescription = ''
	if (article.short_description.text) {
		shortDescription = article.short_description.text
	} else if (article.short_description[0] && article.short_description[0].text) {
		shortDescription = article.short_description[0].text
	}

	let linkArea;
	let destination;
	let slug;
	
	if (article.external_destination === undefined) {
		slug = null;
	} else {
		slug = article.external_destination.url;
	}

	destination = `/${article.uid}`;

    if (slug !== null && slug !== undefined) { destination = (slug.includes('http') === -1) ? `https://${slug}` : slug; }

	linkArea = <Picture
			className="CardArticle__Image"
			src={imageSrc}
			srcSet={imageSrcSet}
			alt={article.image.alt}
			sizes={type === 'big' ? '(max-width: 414px) and (min-height: 500px) 100vw, 69.37vw' : '(max-width: 414px) and (min-height: 500px) 100vw, 30.48vw'}
			linksTo={destination}
			linkClassName="CardArticle__ImageContainer"
			linkTarget='_blank'
	/>

	return (
		<div className={`CardArticle CardArticle--${type}`}>
			{linkArea}
			<div className="CardArticle__Texts">
				<h3 className="CardArticle__Title">
					<Link to={destination}>
						{title}
					</Link>
				</h3>
				<div className="CardArticle__Infos">
					<p>{article.category}</p>
					<p>{article.firstPublicationDate}</p>
				</div>
				<p className="CardArticle__Description">
					{shortDescription}
				</p>
			</div>
		</div>
	)
}

export default CardArticle;