import React, { useEffect } from 'react'
import Picture from './Picture';

const SliderArticle = ({ slides }) => {

	const slidesLength = slides.length;
	let currentIndex = 0;

	// Init image and caption
	useEffect(() => {
		document.querySelector('.SliderArticle__ImageItem:first-child').classList.add('current');
		document.querySelector('.SliderArticle__Caption:first-child').classList.add('current');
	}, [])

	const updateCurrentSlide = (dir) => {
		let canTransform = false;
		if (dir === 'prev') {
			if (currentIndex > 0) {
				currentIndex -= 1;
			}
			else {
				currentIndex = slidesLength - 1;
			}
			canTransform = true;
		}
		else if (dir === 'next') {
			if (currentIndex < slidesLength - 1) {
				currentIndex += 1;
			}
			else {
				currentIndex = 0;
			}
			canTransform = true;
		}

		if (!canTransform) return;

		// Update image
		document.querySelector('.SliderArticle__ImageItem.current').classList.remove('current');
		[...document.querySelectorAll('.SliderArticle__ImageItem')][currentIndex].classList.add('current');

		// Update index
		const indexRollerEl = document.querySelector('.SliderArticle__IndexRoller');
		const indexElHeight = indexRollerEl.children[0].offsetHeight;
		indexRollerEl.style.transform = `translate3d(0, ${(indexElHeight - 1) * -currentIndex}px, 0)`;

		// Update caption
		document.querySelector('.SliderArticle__Caption.current').classList.remove('current');
		[...document.querySelectorAll('.SliderArticle__Caption')][currentIndex].classList.add('current');

	}

	return (
		<div className="SliderArticle">
			<div className="SliderArticle__Left">
				<div className="SliderArticle__Index">
					<div className="SliderArticle__IndexRoller">
						{slides.map((slide, index) => (
							<div
								className="SliderArticle__IndexItem"
								key={`article-slider-index-${index}`}
							>
								{index < 10 ? `0${index + 1}` : index + 1}
							</div>
						))}
					</div>
					<span className="SliderArticle__IndexSlash"> / </span>
					<span> {slidesLength < 10 ? `0${slidesLength}` : slidesLength} </span>
				</div>
				<div className="SliderArticle__CaptionContainer">
					{slides.map((slide, index) => (
						<p
							className="SliderArticle__Caption"
							key={`article-slider-caption-${index}`}
						>
							{slide.slide_caption.text}
						</p>
					))}
				</div>
				<div className="SliderArticle__Arrows">
				<svg className="SliderArticle__Arrow SliderArticle__Arrow--Left" onClick={() => { updateCurrentSlide('prev') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip1)" stroke="#fff" strokeWidth="1.5"><path d="M0 10.17h17.97M9.71 19.179l8.977-9.008L9.71 1.163"/></g><defs><clipPath id="clip1"><path fill="#fff" transform="translate(0 .493)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
					<svg className="SliderArticle__Arrow SliderArticle__Arrow--Right" onClick={() => { updateCurrentSlide('next') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip1)" stroke="#fff" strokeWidth="1.5"><path d="M0 10.17h17.97M9.71 19.179l8.977-9.008L9.71 1.163"/></g><defs><clipPath id="clip1"><path fill="#fff" transform="translate(0 .493)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
				</div>
			</div>

			<ul className="SliderArticle__Images">
				{slides.map((slide, index) => (
					<li
						className="SliderArticle__ImageItem"
						key={`article-slider-image-${index}`}
					>
						<Picture
							className="SliderArticle__Image"
							src={slide.slide_image.fluid.src}
							srcSet={slide.slide_image.fluid.srcSet}
						 	alt={slide.slide_image.alt}
							sizes="69.44vw"
						/>
					</li>
				))}
			</ul>

		</div>
	)
}

export default SliderArticle