import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import SliderArticle from '../components/SliderArticle'
import SectionNextArticle from '../components/SectionNextArticle'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CardArticle from '../components/CardArticle'
import SplitText from '../components/SplitText'
import ParallaxImage from '../components/ParallaxImage'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { RichText } from 'prismic-reactjs'

const Article = ({ pageContext, data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const article = data.prismicArticle.data
	const firstPublicationDate = data.prismicArticle.first_publication_date;
	const slices = article.body;
	const relatedArticles = data.allPrismicArticle.nodes.map(article => {
		return {
			uid: article.uid,
			firstPublicationDate: article.first_publication_date,
			...article.data
		}
	})

	return (
		<div>
			<Header currentPage="article" navigation={prismicNavigation} footer={prismicFooter} />
			<div className="Article">
				<h1
					className="Article__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						{article.title.text}
					</SplitText>
				</h1>

				<div className="Article__ImageContainer">
					<ParallaxImage
						src={article.image.fluid.src}
						srcSet={article.image.fluid.srcSet}
						alt={article.image.alt}
						className="Article__Image"
						sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.44vw"
					/>
				</div>

				<div className="Article__Top">
					<div className="Article__Infos">
						<ul className="Article__Details">
							<li> {article.category} </li>
							<li> {firstPublicationDate} </li>
							<li> Shildan Group </li>
						</ul>
						<div className="Article__Credits">
							<RichText render={article.credits.raw} />
						</div>
					</div>
					<div className="Article__Introduction">
						<h4 className="Article__IntroductionTitle">Introduction</h4>
						<div className="Article__IntroductionParagraphs">
							<RichText render={article.introduction.raw} />
						</div>
					</div>
				</div>

				<section className="Article__Slices">
					{slices.map((slice, index) => {
						if (slice.slice_type === 'slider') {
							return (
								<SliderArticle
									slides={slice.items}
									key={`slider-article-item-${index}`}
								/>
							)
						}
						else if (slice.slice_type === 'paragraphs') {
							return (
								<div
									className="Article__Paragraphs"
									key={`slider-article-item-${index}`}
								>
									<RichText render={slice.primary.paragraphs.raw} />
								</div>
							)
						}
						else if (slice.slice_type === 'quote') {
							return (
								<p
									className="Article__Quote"
									key={`slider-article-item-${index}`}
								>
									{slice.primary.quote.text}
								</p>
							)
						}
						return <div></div>;
					})}
				</section>

				<SectionNextArticle article={pageContext.next} />

				<section className="Article__Related">
					<h5 className="Article__RelatedTitle">Related Articles</h5>
					<Link to="/blog" className="Article__RelatedLink">Back to All Articles</Link>
					<div className="Article__RelatedList">
						{relatedArticles.map((article, index) => (
							<CardArticle
								type="small"
								article={article}
								key={`related-article-index-${index}`}
							/>
						))}
					</div>
				</section>

			</div>

			<Footer footer={prismicFooter.data} />
		</div>
	)
}

export const articleQuery = graphql`
  query articleByUid($uid: String!, $category: String!) {
		prismicArticle(uid: {eq: $uid}) {
			uid
			first_publication_date(formatString: "MM/DD/yy")
			data {
				title {
					text
				}
				image {
					alt
					fluid {
						src
						srcSet
					}
				}
				category
				credits {
					raw
				}
				introduction {
					raw
				}
				body {
					... on PrismicArticleBodySlider {
						id
						slice_type
						items {
							slide_image {
								alt
								fluid {
									src
									srcSet
								}
							}
							slide_caption {
								text
							}
						}
					}
					... on PrismicArticleBodyParagraphs {
						slice_type
						primary {
							paragraphs {
								raw
							}
						}
					}
					... on PrismicArticleBodyQuote {
						id
						slice_type
						primary {
							quote {
								text
							}
						}
					}
				}
			}
		}
		allPrismicArticle(filter: {data: {category: {eq: $category}}, uid: {ne: $uid}}, limit: 3) {
			nodes {
				uid
				first_publication_date(formatString: "MM/DD/yy")
				data {
					title {
						text
					}
					category
					short_description {
						text
					}
					image {
						alt
						fluid {
							src
							srcSet
						}
					}
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
  }
`

export default Article;