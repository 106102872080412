import React from 'react'
import { Link } from 'gatsby'
import Picture from './Picture'

const SectionNextArticle = ({ article }) => {

	return (
		<section className="SectionNextArticle">
			<h6 className="SectionNextArticle__Label">
				Next to read:
			</h6>
			<h3 className="SectionNextArticle__Title">
				<Link to={`/${article.uid}`}> {article.data.title.text} </Link>
			</h3>
			<div className="SectionNextArticle__ImageContainer">
				<Picture
					src={article.data.image.fluid.src}
					srcSet={article.data.image.fluid.srcSet}
					alt={article.data.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.44vw"
					className="SectionNextArticle__Image"
					linksTo={`/${article.uid}`}
				/>
			</div>
		</section>
	)
}

export default SectionNextArticle